body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  

.app {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    height: 100vh;
    background-color: rgba($color: #4287f5, $alpha: 0.5)
  }

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60vh;
  background-color :darkcyan;
  margin-top: 0px;
  max-width: 50%;
}

.input-container {
  display: flex;
  flex-direction: column;
  width: 25vh {/*With this scale horizontal*/};
  gap: 10px;
  margin: 10px;
  padding: 5px 20px;
  justify-content: space-around;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form-box {
  background-color : white {/*background color for login form*/};
  padding: 3rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

.forgotPassword{
    margin-top: 10px;
    text-align: center;
}

.Login-header{
    text-align: center;
    font-size: 15px;
    font-weight: bold;
}

.logo-img{
  max-width: 100%;
  height: auto;
  width: 25vh;
  border-radius: 10px;
}

.go-to-qr-scanner-button{
  display: flex;
  align-items: center;
  justify-content: center;
}
