.container{
    height: 100vh;
    width: 100vw;
}

.header{
    background-color: lightblue;
    display:flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    .collapseButton{
        padding: 2vw;
    }
    .darkmodeButton{
        padding: 2vw;
    }
}

.content{
    background-color: white;
}

.buttoncolapser{
    display:flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    //padding: 4px;
    // .collapseButton{
    //     padding: 2vw;
    // }
    // .darkmodeButton{
    //     padding: 2vw;
    // }
    // set at the bottom of container
    position: absolute;
    bottom: 0;
    width: 100%;
    //center in the same position

    .buttoncolapser{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.collapser{
    // center in the middle of the div
    display: flex;
    height: 40px;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    // set at the bottom of container
    width: 100%;
    //center in the same position
    // arrange 2 components one over the other
    flex-direction: column;
}

.logout{
    display:flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    // center in the middle of the div
    display: flex;
    justify-content: center;
    align-items: center;
    // set at the bottom of container
    position: absolute;
    bottom: 100%;
    width: 100%;
    //center in the same position
}
